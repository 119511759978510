import Language from './Language';
import Utilities from "./Utilities";
import Filter from './Filter';

export default class BlocksFilter extends Filter {
  
  constructor() {
    super();
  }

  init() {
		setTimeout(() => {
			this.filterBlocks();
		}, 100);
  }

  filterBlocks() {
    Utilities.ready(() => {
      var blacklist = super.createBlacklist('block');
			var blockTags = document.querySelectorAll('[data-block]');
      for (var blockTag of blockTags) {
        if (blacklist.indexOf(blockTag.dataset.consent) < 0) {
					if (blockTag.hasAttribute('data-cc-src') && !blockTag.hasAttribute('src')) {
						blockTag.src = blockTag.attributes['data-cc-src'].value;
					}
					this.removePlaceholder(blockTag);
        }
				else { 
					if (blockTag.hasAttribute('src')) {
						blockTag.removeAttribute('src');
					}
					this.makePlaceholder(blockTag);
				}
      }
    });
  }

	makePlaceholder(blockTag) {
		if (!blockTag.classList.contains("cc-hide-block")) {
			blockTag.classList.add('cc-hide-block');
		}
		if (blockTag.attributes['data-block'].value == 'hide') {
			return;
		}
		else if (blockTag.attributes['data-block'].value == 'show') {
			var blockPlaceholder = blockTag.parentNode.querySelector('.cc-block-placeholder');
			if (!blockPlaceholder) {
				var placeholderElement = document.createElement('div');
				placeholderElement.className = 'cc-block-placeholder';
				placeholderElement.innerHTML = Language.getTranslation(window.CookieConsent.config.theme, window.CookieConsent.config.language.current, 'blocksPlaceholderHTML');
				blockTag.parentNode.insertBefore(placeholderElement, blockTag);
				Array.prototype.forEach.call(placeholderElement.getElementsByClassName('ccb__edit'), (edit) => {
					edit.addEventListener('click', (event) => {
						event.preventDefault();
						window.CookieConsent.lastActiveElement = document.activeElement;
						document.getElementById('cconsent-modal').className = 'ccm--visible';
						document.querySelector('.ccm__cheading__close').focus();
					});
				});
			}
		}
	}
	
	removePlaceholder(blockTag) {
		var blockPlaceholder = blockTag.parentNode.querySelector('.cc-block-placeholder');
		if (blockPlaceholder) {
			blockPlaceholder.remove();
		}
		if (blockTag.classList.contains("cc-hide-block")) {
			blockTag.classList.remove('cc-hide-block');
		}
	}
}